import { Chip } from '../FieldChip/FieldChip';
import css from './FieldChipSelectInput.module.css';

const ChipSelectInput = ({
  id,
  options,
  value,
  required,
  disabled,
  readonly,
  multiple,
  autofocus,
  onChange,
  chipClassName,
}: any) => {
  return (
    <div id={id} className={css.chipContainer}>
      {(options.enumOptions || []).map((option, i) => {
        return (
          <Chip
            chipClassName={chipClassName}
            key={option.label}
            onClick={() => {
              if (disabled || readonly) {
                return;
              }

              if (multiple) {
                const newValue = [...value];
                const index = newValue.indexOf(option.value);
                if (index > -1) {
                  newValue.splice(index, 1);
                } else {
                  newValue.push(option.value);
                }
                return onChange(newValue);
              }

              if (value !== option.value) {
                onChange(option.value);
              }
            }}
            data-active={multiple ? value.includes(option.value) : value === option.value}
          >
            {option.label}
          </Chip>
        );
      })}
    </div>
  );
};

export default ChipSelectInput;
