import React from 'react';

import css from './SearchResultsEmptyState.module.css';
import { PrimaryButton } from 'components';

const SearchResultsEmptyState: React.FC<{ onCtaClick?: () => void }> = ({ onCtaClick }) => {
  return (
    <div className={css.root}>
      <h1>No matches found</h1>
      <p>
        It looks like we don't have what you're looking for right now. Try adjusting your filters to
        discover more!
      </p>
      {onCtaClick && <PrimaryButton onClick={onCtaClick}>Clear filters</PrimaryButton>}
    </div>
  );
};

export default SearchResultsEmptyState;
