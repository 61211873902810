import classNames from 'classnames';
import React from 'react';

import { Button, NamedLink } from '../../../components';
import { FormattedMessage } from '../../../util/reactIntl';
import { useFilterQueryParams } from '../SearchPage.hooks';
import css from './SearchHeader.module.css';
import { IconTune } from 'assets/icons';
import { ConditionType } from 'config/configListing';
import { GetListingSortByOptions } from 'hooks/api/listings/useGetListings';
import { DEFAULT_SORTING } from 'util/constants';
import { categoryOptions } from 'util/productOptions/productTypeDefinitions';

const categories = categoryOptions.filter(o => ['clothing', 'bags', 'shoes'].includes(o.value));

type MainPanelHeaderProps = {
  rootClassName?: string;
  className?: string;
  toggleFilters: () => void;
  isFiltersOpen: boolean;
};

const SearchHeader: React.FC<MainPanelHeaderProps> = props => {
  const { rootClassName, className, toggleFilters, isFiltersOpen } = props;
  const classes = classNames(rootClassName || css.root, className);
  const [{ category, sortBy, condition, preloved }, setParams, generateNewParams] =
    useFilterQueryParams();

  return (
    <div className={classes}>
      <div className={css.categoryOptions}>
        <NamedLink
          active={!category && !condition && !preloved}
          className={css.link}
          name="SearchPage"
          to={{
            search: generateNewParams({
              category: '',
              condition: '',
              preloved: false,
              sortBy: DEFAULT_SORTING,
            }),
          }}
        >
          ALL
        </NamedLink>

        <NamedLink
          active={preloved}
          className={css.link}
          name="SearchPage"
          to={{
            search: generateNewParams({
              category: '',
              condition: '',
              preloved: true,
              sortBy: DEFAULT_SORTING,
            }),
          }}
        >
          PRE-LOVED
        </NamedLink>
        <NamedLink
          active={condition === ConditionType.SampleSale}
          className={css.link}
          name="SearchPage"
          to={{
            search: generateNewParams({
              category: '',
              condition: ConditionType.SampleSale,
              preloved: false,
              sortBy: DEFAULT_SORTING,
            }),
          }}
        >
          SAMPLES
        </NamedLink>

        {categories.map(c => (
          <NamedLink
            key={c.value}
            active={Boolean(category === c.value)}
            className={css.link}
            name="SearchPage"
            to={{
              search: generateNewParams({
                category: c.value,
                condition: '',
                preloved: false,
                sortBy: DEFAULT_SORTING,
              }),
            }}
          >
            {c.label}
          </NamedLink>
        ))}
      </div>
      <Button
        className={css.filterButton}
        onClick={toggleFilters}
        data-open={Boolean(isFiltersOpen)}
      >
        <IconTune />
        <FormattedMessage id="MainPanelHeader.filters" />
      </Button>
      <label className={css.sortBy}>
        <span>Sort by:</span>
        <select
          onChange={e => {
            setParams({ sortBy: e.target.value });
          }}
        >
          {GetListingSortByOptions.map(option => (
            <option value={option.value} selected={option.value === sortBy}>
              {option.label}
            </option>
          ))}
        </select>
      </label>
    </div>
  );
};

export default SearchHeader;
