import ChipSelectInput from '../FieldChipSelectInput/FieldChipSelectInput';
import css from './FieldChipSelectColorsInput.module.css';

export const colorOptions = [
  { option: 'White', label: 'White', color: '#ffffff' },
  { option: 'Black', label: 'Black', color: '#000000' },
  { option: 'Grey', label: 'Grey', color: '#808080' },
  { option: 'Nude', label: 'Nude', color: '#e3bc9a' },
  { option: 'Blue', label: 'Blue', color: '#0000ff' },
  { option: 'Green', label: 'Green', color: '#008000' },
  { option: 'Orange', label: 'Orange', color: '#ffa500' },
  { option: 'Red', label: 'Red', color: '#ff0000' },
  { option: 'Brown', label: 'Brown', color: '#964b00' },
  { option: 'Metallic', label: 'Metallic', color: '#aaa9ad' },
  { option: 'Yellow', label: 'Yellow', color: '#ffff00' },
  { option: 'Purple', label: 'Purple', color: '#800080' },
  { option: 'Pink', label: 'Pink', color: '#ffc0cb' },
  { option: 'Fuchsia', label: 'Fuchsia', color: '#ff00ff' },
  { option: 'DarkBlue', label: 'Dark Blue', color: '#00008B' },
  { option: 'Gold', label: 'Gold', color: '#ffd700' },
  { option: 'Silver', label: 'Silver', color: '#c0c0c0' },
  { option: 'Olive', label: 'Olive', color: '#808000' },
  { option: 'Khaki', label: 'Khaki', color: '#f0e68c' },
  { option: 'Ecru', label: 'Ecru', color: '#f3efe0' },
];

const ChipSelectColorsInput = (props: any) => {
  const newOptions = {
    enumOptions: colorOptions.map(o => ({
      value: o.option,
      label: (
        <span className={css.label}>
          <span className={css.colorCircle} style={{ backgroundColor: o.color }} />
          {o.label}
        </span>
      ),
    })),
  };
  return <ChipSelectInput {...props} options={newOptions as any} chipClassName={css.chip} />;
};

export default ChipSelectColorsInput;
