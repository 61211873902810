import * as Slider from '@radix-ui/react-slider';
import React from 'react';

import css from './FieldSliderInput.module.css';

type FieldSliderInputProps = Slider.SliderProps;

export const SliderInput: React.FC<FieldSliderInputProps> = props => {
  return (
    <Slider.Root className={css.root} {...props}>
      <Slider.Track className={css.track}>
        <Slider.Range className={css.range} />
      </Slider.Track>
      <Slider.Thumb className={css.thumb} aria-label="Min" />
      {(props.defaultValue?.length || 0) > 1 && (
        <Slider.Thumb className={css.thumb} aria-label="Max" />
      )}
    </Slider.Root>
  );
};

const FieldSliderInput: React.FC<FieldSliderInputProps> = props => {
  // TODO: Wrap in Field component
  return <SliderInput {...props} />;
};

export default FieldSliderInput;
